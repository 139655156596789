.Honey-product-container {
    background-color: #729b5f82;
    background-size: cover;
    width: 100%;
}

.product-bottle {
    background-color: white;
    border-radius: 8px;
    width: 270px;
    margin-top: 100px;
}

.Honey-product {
    width: 100%;
    border-radius: 8px;
}

.product-card-container {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
}

.preview-product-img {
    height: 7em;
    width: 7em;
    padding: 10px;
    border-radius: 10px;
    padding-top: 20px;
}

.preview-product-img:hover {
    border-style: solid;
    color: rgb(71, 71, 218);
    border-width: 0.5px;
}

.main-product-img {
    height: 20em;
}
.onlycolor3{
    color: rgb(0, 0, 0);
    font-family:'Roboto';
    font-weight: 300;
}

.name-for-product {
    font-size: 2em;
}

.prodcut-size-card {
    display: flex;
    gap: 1.5em;
}
.Honey-product-container-Moringa {
    background-image: url("https://res.cloudinary.com/drevfgyks/image/upload/v1712594551/jar-honey-antique-table-with-fields-flower-background_1_b1dzue.jpg");
    background-size: cover;
    width: 100%;
}

.prodcut-size-Moringa{
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-width: 0.2px;
    padding: 0.5em;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 500;
    font-family: 'Roboto';
}

.prodcut-size-Moringa:hover {
    scale: 1.1;
    background-color: #346700;
    color: white;
}

.product-quantity {
    display: flex;
    align-items: center;
    gap: 1em;
}

.add-to-cart-btn,
.buy-now-btn {
    margin: 1em;
    background-color: rgb(238, 165, 29);
    width: 12em;
    border-style: none;
    padding: 1em;
    color: #fff;
    border-radius: 8px;
    box-shadow: 0 13px 27px 0 rgb(93, 84, 53);
    text-transform: uppercase;
    transition: 0.5s;
    cursor: pointer;
}

.add-to-cart-btn:hover,
.buy-now-btn:hover {
    border-color: blue;
    scale: 1.1;
}




/*Recomend styles*/

.recomend-product-card {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    width: 80%;
}

.recomend-product-image {
    width: 100%;
    border-radius: 8px;
}

.recomend-product-details {
    padding: 10px 0;
}

.recomend-product-name {
    font-size: 1em;
    margin-bottom: 10px;
}

.recomend-product-price {
    font-size: 1em;
    color: #007bff;
    margin-bottom: 10px;
}

.recomend-product-size {
    font-size: 1em;
    margin-bottom: 10px;
}

.recomend-button-container {
    display: flex;
    justify-content: space-between;
}

.recomend-add-to-cart-button,
.recomend-buy-now-button {
    padding: 5px 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.recomend-add-to-cart-button:hover,
.recomend-buy-now-button:hover {
    background-color: #0056b3;
    color: #fff;
}

.menu-section-heading {
    text-align: center;
}

.explore-menu-section2 {
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
    padding: 20px 0;
}
.footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 20px 0;
}

.footer a {
    color: #fff;
}

.footer a:hover {
    text-decoration: underline;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.hrClass{
    margin-top: 120px;
    width: 100%;
}

@media (max-width: 768px) {
    .recomend-product-card {
        background-color: white;
        border-radius: 8px;
        padding: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
        width: 80%; /* Adjust width if needed */
    }
}

/* Add any additional styling for responsiveness here */

.button_container {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px; /* Adjust margin as needed */
    gap: 22px;
}

.button_BuyNow {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button_BuyNow:hover {
    background-color: #233f5d;
}

.button_AddCart {
    background-color: rgb(238, 165, 29);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button_AddCart:hover {
    background-color: #58491b;
}


.onlycolor{
    color: rgb(50, 50, 50);
}


/*For Mobile Devices*/
@media only screen and (max-width: 767px){
    .prodcut-size-Moringa{
        font-size: 12px;
        box-shadow: 0 13px 27px 0 rgb(149, 149, 149);
        border-style: none;
        border-color: none;
        border-radius: 8px;
    }
    .cardcenter{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }  

}

/*For Tablets*/
@media only screen and (min-width: 768px) and (max-width: 991px){
    .prodcut-size-Moringa{
        text-align: center;
        font-size: 16px;
        width: 120px;
        box-shadow: 0 13px 27px 0 rgb(149, 149, 149);
        border-style: none;
        border-color: none;
        border-radius: 8px;
    }
    .cardcenter{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
    }
    .Honey-product-container{
        width: 100%;
    }
}

/*For Laptops*/
@media only screen and (min-width:992px) and (max-width:1199px){
    .prodcut-size-Moringa{
        text-align: center;
        font-size: 16px;
        width: 120px;
        box-shadow: 0 13px 27px 0 rgb(149, 149, 149);
        border-style: none;
        border-color: none;
        border-radius: 8px;
    }
    .cardcenter{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 35px;
    }
    
}

/* Large Devices like desktop and TV and more large*/
@media only screen and (min-width:1200px) {
    .prodcut-size-Moringa{
        text-align: center;
        font-size: 16px;
        width: 190px;
        box-shadow: 0 13px 27px 0 rgb(149, 149, 149);
        border-style: none;
        border-color: none;
        border-radius: 8px;
    }
    .cardcenter{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 65px;
    }
}