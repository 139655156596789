/* src/components/Loader.css */

.loader {
    width: 100%;
    height: 100vh; /* Ensure the loader takes full viewport height */
  }
  
  .truckWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    overflow-x: hidden;
  }
  
  /* truck upper body */
  .truckBody {
    height: fit-content;
    animation: motion 1s linear infinite;
  }
  
  /* truck suspension animation */
  @keyframes motion {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(6px);
    }
    100% {
      transform: translateY(0px);
    }
  }
 

  
  /* truck's tires */
  .truckTires {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
  }
  
  .Orderconfoem-heading{
        font-family:serif;
  }
  
  /* road */
  .road {
    width: 100%;
    height: 3px;
    background-color: #282828;
    position: relative;
    bottom: 0;
    align-self: flex-end;
    border-radius: 3px;
  }
  
  .road::before,
  .road::after {
    content: "";
    position: absolute;
    height: 100%;
    background-color: #282828;
    border-radius: 3px;
    animation: roadAnimation 1.4s linear infinite;
  }
  
  .road::before {
    border-left: 20px solid white;
  }
  
  .road::after {
    border-left: 8px solid white;
  }
  
  .lampPost {
    position: absolute;
    bottom: 0;
    animation: roadAnimation 1.4s linear infinite;
  }
  
  @keyframes roadAnimation {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(-700px);
    }
  }
  
  /* For Mobile Devices */
  @media only screen and (max-width: 768px){
    
    .loader{
        margin-top: 250px;
        margin-left: 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;  
    }
    .truckWrapper {
      width: 200px;
      height: 100px;
    }
    .truckBody {
      width: 130px;
      margin-bottom: 6px;
    }
    .truckTires {
      width: 130px;
      padding: 0px 10px 0px 15px;
    }
    .truckTires svg {
      width: 24px;
    }
    .road::before {
      width: 20px;
      right: -50%;
    }
    .road::after {
      width: 10px;
      right: -65%;
    }
    .lampPost {
      height: 90px;
    }
  }
  
  /* For Tablets */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .loader{
        margin-top: 260px;
        margin-left: 350px; 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;  
    }
    .truckWrapper {
      width: 300px;
      height: 150px;
    }
    .truckBody {
      width: 195px;
      margin-bottom: 9px;
    }
    .truckTires {
      width: 195px;
      padding: 0px 15px 0px 20px;
    }
    .truckTires svg {
      width: 36px;
    }
    .road::before {
      width: 30px;
      right: -50%;
    }
    .road::after {
      width: 15px;
      right: -65%;
    }
    .lampPost {
      height: 135px;
    }
  }
  
  /* For Laptops */
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .loader{
        margin-top: 250px;
        margin-left: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;   
    }
    .truckWrapper {
      width: 350px;
      height: 175px;
    }
    .truckBody {
      width: 227.5px;
      margin-bottom: 10.5px;
    }
    .truckTires {
      width: 227.5px;
      padding: 0px 17.5px 0px 25px;
    }
    .truckTires svg {
      width: 42px;
    }
    .road::before {
      width: 35px;
      right: -50%;
    }
    .road::after {
      width: 17.5px;
      right: -65%;
    }
    .lampPost {
      height: 157.5px;
    }
  }
  
  /* For Large Devices */
  @media only screen and (min-width: 1200px) {
    .loader{
        margin-top: 300px;
        margin-left: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;
    }
    .truckWrapper {
      width: 400px;
      height: 200px;
    }
    .truckBody {
      width: 260px;
      margin-bottom: 12px;
    }
    .truckTires {
      width: 260px;
      padding: 0px 20px 0px 30px;
    }
    .truckTires svg {
      width: 48px;
    }
    .road::before {
      width: 40px;
      right: -50%;
    }
    .road::after {
      width: 20px;
      right: -65%;
    }
    .lampPost {
      height: 180px;
    }
    .continue-shopping-btn {
        background-color: #007bff;
        color: white;
        padding: 8px 6px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 12px;
    }
    
    .continue-shopping-btn:hover {
        background-color: #0056b3;
    }
  }
  