/* Common CSS */
.preview-product-img {
    height: 8em;
    width: 8em;
    padding: 15px;
    border-radius: 10px;
    padding-top: 20px;
}

.preview-product-img:hover {
    border-style: solid;
    color: rgb(71, 71, 218);
    border-width: 0.5px;
}
.onlycolor2{
    color: rgb(0, 0, 0);
    font-family:'Roboto';
    font-weight: 300;
}
.product-pics-container {
    background-color: white;
    height: 453px;
    background-size: cover;
    border-radius: 8px;
}

.main-product-img {
    height: 20em;
}

.preview-imgs {
    display: flex;
    justify-content: center;
    align-items: center;
}

.honeybuypage-container {
    background-image: linear-gradient(#154851, #874528);
    background-size: cover;
    height: 100vh;
    display: flex;
    margin-top: 5%;
    gap: 5em;
    padding: 4em;
}

.product-bottle {
    width: 420px;
    height: 20vh;
    box-shadow: 0 13px 10px 0 rgba(0, 0, 0, 0.3);
}

.Honey-product {
    width: 100%;
    height: 180px;
}

.menu-section-heading {
    color: white;
}

.select-container2, .select-container {
    color: white;
    background-color: rgb(2, 2, 2);
    border-radius: 5px;
}

.name-for-product, .name-for-productself {
    color: white;
    font-size: 2em;
}

.prodcut-size-card {
    color: white;
    display: flex;
    gap: 1.5em;
}

.prodcut-size-Arabica {
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-width: 0.2px;
    padding: 0.5em;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 500;
    font-family: 'Roboto';
}

.prodcut-size-Arabica:hover {
    scale: 1.1;
    background-color: #346700;
    color: white;
}

.product-quantity {
    display: flex;
    align-items: center;
    gap: 1em;
}

.add-to-cart-btn, .button_BuyNow, .button_BuyNowself {
    margin: 1em;
    background-color: rgb(0, 160, 196);
    border-style: none;
    padding: 1em;
    color: #fff;
    width: 12em;
    border-radius: 8px;
    box-shadow: 0 13px 27px 0 rgb(93, 84, 53);
    text-transform: uppercase;
    transition: 0.5s;
    cursor: pointer;
}
.onlycolor{
    color: rgb(50, 50, 50);
}

.add-to-cart-btn:hover, .buy-now-btn:hover {
    border-color: blue;
    scale: 1.1;
}

.prdouct-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgb(246, 245, 245);
    background-size: cover;
    width: 50%;
    padding: 22px;
    height: 450px;
    border-radius: 8px;
}

.product-discription {
    font-size: 18px;
    text-align: start;
}

.product-price {
    font-size: 28px;
    align-self: flex-start;
}

.price-span {
    color: black;
    font-size: bold;
    font-size: 18px;
}

/* Media Queries */

/* Mobile Devices */
@media only screen and (max-width: 767px) {
    .prodcut-size-Arabica {
        font-size: 12px;
        box-shadow: 0 13px 27px 0 rgb(149, 149, 149);
        border-style: none;
        border-radius: 8px;
    }

    .cardcenter {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .Honey-product-container-Aca {
        background-image: url("https://res.cloudinary.com/drevfgyks/image/upload/v1716220538/B%20organics/working-bee-filling-honey-combs_1_ishjkj.jpg");
        background-size: cover;
        width: 100%;
    }
}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .prodcut-size-Arabica {
        text-align: center;
        font-size: 16px;
        width: 120px;
        box-shadow: 0 13px 27px 0 rgb(149, 149, 149);
        border-style: none;
        border-radius: 8px;
    }

    .cardcenter {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
    }

    .Honey-product-container-Aca {
        background-image: url("https://res.cloudinary.com/drevfgyks/image/upload/v1716216737/honeycomb-lemon-slice-with-dipper-oil-colored-background_esypdw.jpg");
        background-size: cover;
        width: 100%;
    }
}

/* Laptops */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .prodcut-size-Arabica {
        text-align: center;
        font-size: 16px;
        width: 120px;
        box-shadow: 0 13px 27px 0 rgb(149, 149, 149);
        border-style: none;
        border-radius: 8px;
    }

    .cardcenter {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 35px;
    }
    .Honey-product-container-Aca {
        background-image: url("https://res.cloudinary.com/drevfgyks/image/upload/v1716216737/honeycomb-lemon-slice-with-dipper-oil-colored-background_esypdw.jpg");
        background-size: cover;
        width: 100%;
    }
}

/* Large Devices */
@media only screen and (min-width: 1200px) {
    .prodcut-size-Arabica {
        text-align: center;
        font-size: 16px;
        width: 190px;
        box-shadow: 0 13px 27px 0 rgb(149, 149, 149);
        border-style: none;
        border-radius: 8px;
    }

    .cardcenter {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 65px;
        margin-bottom: 105px;
    }
    .Honey-product-container-Aca {
        background-image: url("https://res.cloudinary.com/drevfgyks/image/upload/v1716220538/B%20organics/working-bee-filling-honey-combs_1_ishjkj.jpg");
        background-size: cover;
        width: 100%;
        /*filter: blur(5px); Adjust the blur level as needed */
    }
    
}
