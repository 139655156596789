/* src/components/NotFound/index.css */

.not-found-route-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #ffffff; /* Use the desired background color */
  }
  
  .not-found-image {
    max-width: 50%;
    max-height: 50%;
  }
  