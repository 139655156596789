/* ShippingPolicy.css */

.shipping-policy-container {
    max-width: 1000px;
    margin: 0 auto;
}

.shipping-policy-row {
    display: flex;
    flex-direction: column;
}

.shipping-policy-h1 {
    font-size: 2.5rem;
    color: #333;
}

.shipping-policy-h3 {
    font-size: 1.5rem;
    color: #555;
    margin-top: 20px;
}

.shipping-policy-paragraph {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #777;
    margin-bottom: 20px;
}

/* Cursor styles */
body {
    cursor: none;
}

.cursol {
    position: fixed;
    width: 50px;
    height: 50px;
    border: 1px solid #c6c6c6;
    border-radius: 50%;
    left: 0;
    top: 0;
    pointer-events: none;
    transform: translate(-50%, -50%);
    transition: 0.1s;
    background-color: transparent; /* Initially transparent */
}

.cursol2 {
    position: fixed;
    width: 8px;
    height: 8px;
    background-color: #c6c6c6;
    border-radius: 50%;
    left: 0;
    top: 0;
    pointer-events: none;
    transform: translate(-50%, -50%);
    transition: 0.15s;
}

.hover-effect .cursol {
    background-color: #c6c6c6; /* Filled color */
}

.whyus-para:hover ~ .cursol {
    transform: translate(-50%, -50%) scale(1.5);
    background-color: #003206;
    opacity: 0.5;
}

.whyus-para:hover ~ .cursol2 {
    opacity: 0;
}
