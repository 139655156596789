.order-summary-container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  height: 100vh;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .order-summary-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .order-details {
    margin-bottom: 20px;
  }
  
  .order-item {
    display: flex;
    margin-bottom: 10px;
  }
  
  .item-label {
    font-weight: bold;
    width: 150px;
  }
  
  .item-value {
    flex: 1;
  }
  
  .place-order-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .place-order-btn:hover {
    background-color: #0056b3;
  }
  