.mailsent-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
}
.righttick{
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.img-tick{
    margin-top: -15px;
    width: 50px;
}
.Receivedmail{
    font-size: 18px;
    font-family: monospace;
}

/*For Mobile Devices*/
@media only screen and (max-width: 767px){
    .mailsent-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 80px;
    }
    .righttick{
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .img-tick{
        margin-top: -15px;
        width: 70px;
    }
    .Receivedmail{
        margin-top: 15px;
        font-size: 14px;
        font-family: 'Roboto';
    }
    
}

/*For Tablets*/
@media only screen and (min-width: 768px) and (max-width: 991px){

}

/*For Laptops*/
@media only screen and (min-width:992px) and (max-width:1199px){

}

/* Large Devices like desktop and TV and more large*/
@media only screen and (min-width:1200px) {}