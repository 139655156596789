.customerdetails-container {
  padding: 20px;
  margin-top: 120px; /* Adjusted margin for better alignment */
}

.AdminDashboard_h3 {
  font-size: 25px;
  font-family: serif;
  text-align: center;
  margin-bottom: 20px;
  color: #333; /* Darkened header text color */
}

.list-of-orders {
  overflow-x: auto;
}

.AdminDashboard_table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Added box shadow for depth */
}

.AdminDashboard_table th,
.AdminDashboard_table td {
  border: 1px solid #ddd;
  padding: 12px; /* Increased padding for better spacing */
  text-align: left;
}

.AdminDashboard_table th {
  cursor: pointer;
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
  z-index: 1; /* Ensure header stays above content */
}

.AdminDashboard_table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.AdminDashboard_table tr:hover {
  background-color: #f0f0f0; /* Lighter hover color */
}
.searchinput {
  align-self: center;
  font-size: 25px;
  padding: 10px;
  width: 35%;
  border: 1px solid #ddd;
  border-radius: 12px;
  margin-bottom: 20px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.searchinput:focus {
  outline: none;
  border-color: #007bff; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add shadow on focus */
}

/* Media queries for responsive design */

@media (max-width: 768px) {
  .AdminDashboard_table,
  .AdminDashboard_table thead,
  .AdminDashboard_table tbody,
  .AdminDashboard_table th,
  .AdminDashboard_table td,
  .AdminDashboard_table tr {
    display: block;
  }

  .AdminDashboard_table tr {
    margin-bottom: 15px;
  }

  .AdminDashboard_table td {
    text-align: left; /* Adjusted text alignment */
    padding-left: 50%;
    position: relative;
  }

  .AdminDashboard_table td:before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 12px; /* Adjusted padding */
    font-weight: bold;
    text-align: left;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .AdminDashboard_table {
    font-size: 14px;
  }
}

@media (min-width: 1025px) {
  .AdminDashboard_table {
    font-size: 16px;
  }
}
.logout-icon-container{
  margin-top: 160px;
  margin-right: 12px;
  text-align: center;
  font-family:monospace;
}


