/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"/> */

.coffee-banner{
    height: 20vh;
}
.Honey-products-heading2{
    font-family: informal;
    font-size: 30px;
    color: white;
}
/* .Container-card{
    background-color: rgb(226, 226, 226);
    width: 100%;
    border-radius: 12px;
} */
.card{
    background-color: rgb(255, 255, 255);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    height: 100%;
    margin-bottom: -25px;
}
.buttons-container{
    display: flex;
    justify-content: space-between;
    margin-top: -10px;
}
.card-img-top{
    border-image: fill 0 linear-gradient(#0001, #000);
}
.btn_buybutton{
    width: 100%;
    margin-bottom: 12px;
   
}
.btn_buybutton:hover{
    background-color: rgb(0, 0, 0);
}
.pricetext{
    color: rgb(188, 122, 0);
    font-weight: bold;
}
.Available{
    color: rgb(120, 120, 0);
    font-weight: bold;
    font-size: 12px;
}
.card-text{
    font-size: 12px;
}
.Honey-heading{
    font-size: 15px;
    font-family: 'Georgia', serif;
}

.card-text{
    font-style: italic;
}

.emptyBOX-para{
    font-size: 11px;
    font-family: 'Roboto';
    text-align: center;
}

.emptyBOX-para-Honey{
    font-size: 11px;
    font-family: 'Roboto';
    text-align: start;
    margin-top: 8px;
}
/*For Mobile Devices*/
@media only screen and (max-width: 767px){
    .products-container{
        display: flex;
        justify-content: space-evenly;
    }
}

/*For Tablets*/
@media only screen and (min-width: 768px) and (max-width: 991px){
    .products-container{
        display: flex;
        justify-content: space-evenly;
    }
}

/*For Laptops*/
@media only screen and (min-width:992px) and (max-width:1199px){
    .products-container{
        display: flex;
        justify-content: space-evenly;
    }
}

/* Large Devices like desktop and TV and more large*/
@media only screen and (min-width:1200px) {
    .emptyBOXself{
       gap: 25px;
    }
    .products-container2{
        display: flex;
        justify-content: space-evenly;
        margin-top: -55px;
    }
    .Honey-main-title-container2{
        margin-left: 60px;
        margin-top: 60px;
        padding: 70px;
    }
    .HoneyProduct2-container{
        background-image: url("https://res.cloudinary.com/drevfgyks/image/upload/v1713623450/jar-honey-with-piece-honeycomb-full-natural-sweetness_wjlbsd.jpg");
        height: 100vh;
        background-size: cover;
        width: 100%;
    }
    .emptyBOX-container{
        display: flex;
        justify-content: center;
        gap: 30px;
        
    }
    .emptyBOX{
        margin-left: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        width: 650px;
        height: 150px;
        border-bottom-right-radius: 100px;
        border-top-right-radius: 100px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
        box-shadow: 10px 10px rgb(104, 97, 72);
    }
    .Honey-products-heading2{
        padding-right: -85px;
    }
    
}



/* Cursor styles */
body {
    cursor:none;
}

.cursol {
    position: fixed;
    width: 50px;
    height: 50px;
    border: 1px solid #c6c6c6;
    border-radius: 50%;
    left: 0;
    top: 0;
    pointer-events: none;
    transform: translate(-50%, -50%);
    transition: 0.1s;
    background-color: transparent; /* Initially transparent */
}

.cursol2 {
    position: fixed;
    width: 8px;
    height: 8px;
    background-color: #c6c6c6;
    border-radius: 50%;
    left: 0;
    top: 0;
    pointer-events: none;
    transform: translate(-50%, -50%);
    transition: 0.15s;
}

.hover-effect .cursol {
    background-color: #c6c6c6; /* Filled color */
}

.whyus-para:hover ~ .cursol {
    transform: translate(-50%, -50%) scale(1.5);
    background-color: #003206;
    opacity: 0.5;
}

.whyus-para:hover ~ .cursol2 {
    opacity: 0;
}


.HoneyProducts2-container {
    position: relative;
    width: 100%;
}

.HoneyProducts2-image {
    display: block;
    width: 100%;
    height: auto;
}

.HoneyProducts2-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 140, 186, 0.7); /* Slightly transparent background */
    overflow: hidden;
    width: 0;
    height: 100%;
    transition: .5s ease;
}

.HoneyProducts2-container:hover .HoneyProducts2-overlay {
    width: 100%;
}

.HoneyProducts2-text {
    white-space: nowrap;
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.honey-products2 .HoneyProducts2-card {
    background-color: rgb(255, 255, 255);
}

.honey-products2 .HoneyProducts2-heading {
    font-size: 15px;
    font-family: 'Georgia', serif;
}

.honey-products2 .HoneyProducts2-card-text {
    font-style: italic;
}

.honey-products2 .HoneyProducts2-buttons-container .HoneyProducts2-pricetext {
    margin-right: 10px;
}

/* Empty Box Styles */
.HoneyProducts2-emptyBOX-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 2rem;
}

.HoneyProducts2-emptyBOX {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 1rem;
}

.HoneyProducts2-emptyBOX-para-Honey {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: 'Georgia', serif;
    font-size: 14px;
}

.HoneyProducts2-emptyBOXself {
    background-color: #e9ecef;
}

/* Media Queries */
@media only screen and (max-width: 767px) {
    .HoneyProducts2-products-container2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .HoneyProducts2-Honey-products-heading2 {
        text-align: center;
        margin-top: 35px;
        margin-bottom: 25px;
    }

    .HoneyProducts2-emptyBOX-container {
        flex-direction: column;
        align-items: center;
    }

    .HoneyProducts2-emptyBOX,
    .HoneyProducts2-emptyBOXself {
        margin-bottom: 1rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .HoneyProducts2-products-container2 {
        display: flex;
        justify-content: space-evenly;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .HoneyProducts2-products-container2 {
        display: flex;
    }
}