.app {
      transition: background-image 1s ease-in-out;
      height: 100vh;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
} 
.carousel-item img {
     /* Set maximum height for the images */
    object-fit: cover; /* Ensure images cover the entire carousel item */
}
.whyusimageself{
    margin-right: 35px;
}
.carousel-control-prev,
.carousel-control-next {
    width: 5%; /* Set the width of the navigation buttons */
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.5); /* Set background color for navigation buttons */
}

.carousel-caption {
    background-color: rgba(0, 0, 0, 0.5); /* Set background color for carousel captions */
    color: white; /* Set text color for carousel captions */
}

.carousel-caption h5 {
    font-size: 1.5rem; /* Set font size for carousel caption titles */
}

.carousel-caption p {
    font-size: 1rem; /* Set font size for carousel caption text */
}
.landing-cycle-headingself{
    margin-right: 34px;
}
.landingpage-container {
    width: 100%;
    height: 100vh; /* Set carousel container height to 100% viewport height */
}
.landing-cycle-heading{
    font-size: 16px;
}

.carousel {
    width: 100%;
    height: 100%; /* Set carousel width and height to 100% */
}

.carousel-item img {
    object-fit: cover;
    width: 100%;
    height: 100%; /* Ensure images cover the entire carousel item */
}
.carousel-item {
    transition-duration: 1.0s; /* Adjust the duration as needed (e.g., 0.5s for half a second) */
}
.carousel-container{
    background-color: rgba(0, 68, 30, 0.31);
    background-size: cover;
}

.Freshself{
    width: 120px;
    margin-bottom: -10px;
    margin-top: -12px;
}
/*Products CSS */
.Products-container{
    /* background-color: #e4e4e4; */
    background-color: #EFEEE6;
    background-size: cover;
}
.Landingpage-whoweare-top{
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
}
.organicsimage{
    margin-top: 122px;
    width: 310px;
    margin-bottom: 10px;
    border-radius: 20px;
}
.whoweare-icons{
    background-color: rgba(206, 206, 206, 0.47);
    border: 30px solid rgb(0, 74, 106); /* Set border color and size */
    border-right: none;
    border-top: none;
    border-bottom: none;
    margin: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 8px;
}
.who-icons{
    font-size: 65px;
}

.ProductProccess{
    border-radius: 105px;
}

.whyusimage{
    width: 75px;
}
.whyus-image-comtainer{
    color: black;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 85px;
}
.whyusheadeing{
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 85px;
}
.whyusheadeing2{
    color: black;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.Landingpage-whoweare-top-2{
    /* background-color: rgb(164, 164, 163); */
    color: black;
}
.product-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.whyus-para{
    font-size: 15px;
    text-align: left;
    font-family: 'Gill Sans';
}
.whyusimage-bag{
    align-self: flex-start;
    width: 300px;
}
.heading-1{
    text-align: center;
}
.userfeedback_container{
    margin: 125px;
}
.whowe-are-para{
    text-align: justify;
    font-family:Montserrat;
    font-weight: bold 700;
}

/*For Mobile Devices*/
@media only screen and (max-width: 767px){
    .whyusheadeing2{
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .product-details{
       
    }
    .app-container{
        width: 100%;
    }
    .app {
        width: 100%;
        height: 32vh;
        margin-top: 45px;
    }
    .whyus-para{
        font-size: 15px;
    }
    .whyusimage-bag{
        width: 100px;
    }
    .Landingpage-whoweare{
        display: flex;
        flex-direction: column;
    }
    .whoweare-icons{
        display: flex;
        flex-direction: column;
    }
    .userfeedback_container{
        margin: 35px;
    }
}


/*For Tablets*/
@media only screen and (min-width: 768px) and (max-width: 991px){
    .app {
        width: 100%;
        height: 70vh;
        margin-top: 45px;
    }
    .whyus-para{
        font-size: 15px;
    }
    .whyusimage-bag{
        width: 180px;
    }
    .Landingpage-whoweare{
        display: flex;
        flex-direction: column;
    }
    .whoweare-icons{
        width: 40%;
        display: flex;
    }
    .icons-container{
        display: flex;
    }
}

/*For Laptops*/
@media only screen and (min-width:992px) and (max-width:1199px){
    .app {
        width: 100%;
        height: 70vh;
        margin-top: 45px;
    }
    .whyus-para{
        font-size: 15px;
    }
    .whyusimage-bag{
        width: 180px;
    }
    .Landingpage-whoweare{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    .whoweare-icons{
        width: 40%;
        height: 150px;
        display: flex;
    }
    .icons-container{
        display: flex;
    }
}

/* Large Devices like desktop and TV and more large*/
@media only screen and (min-width:1200px){
    .app {
        width: 100%;
        height: 100vh;
        margin-top: 45px;
    }
    .whyus-para{
        font-size: 15px;
    }
    .whyusimage-bag{
        width: 200px;
    }
    .Landingpage-whoweare{
        display: flex;
        justify-content: space-evenly;
        margin: 58px;
    }
    .icons-container{
        display: flex;
        width: auto;
        font-size: 14px;
    }
    .who-icons{
        font-size: 40px;
    }
    
    .whoweare-icons-self {
        margin-left: 70px;
    }
}


/* Cursor styles */
body {
    cursor: none; 
}
.cursol {
    position: fixed;
    width: 80px;
    height: 80px;
    border: 2px solid #c6c6c6;
    border-radius: 50%;
    left: 0;
    top: 0;
    pointer-events: none;
    transform: translate(-50%, -50%);
    transition: 0.1s;
    background-color: transparent; /* Initially transparent */
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #c6c6c6;
}

.cursol2 {
    position: fixed;
    width: 8px;
    height: 8px;
    background-color: #c6c6c6;
    border-radius: 50%;
    left: 0;
    top: 0;
    pointer-events: none;
    transform: translate(-50%, -50%);
    transition: 0.15s;
}

.hover-effect .cursol {
    background-color: red; /* Filled color when hovering over a link */
    color: white; /* Text color when hovering over a link */
    border-color: red; /* Border color when hovering over a link */
}

