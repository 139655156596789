Contact.css
.contaictus-container{
    display: flex;
}
/* Custom styles for the Box component */
.customBox {
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
.Worning-mes{
  color: red;
}
.spinnerForEmail{
  width: 40px;
  height: 40px;
}
  /* Custom styles for the Input component */
  .customInput {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    transition: border-color 0.3s ease;
  }
  
  .customInput:focus {
    border-color: #007bff;
  }
  
  /* Custom styles for the Textarea component */
  .customTextarea {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    height: 100px;
    transition: border-color 0.3s ease;
  }
  
  .customTextarea:focus {
    border-color: #007bff;
  }
  
  /* Custom styles for the Button component */
  .customButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .customButton:hover {
    background-color: #0056b3;
  }

  .button-Contactus {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    position: relative;
    padding: 0 20px;
    font-size: 18px;
    text-transform: uppercase;
    border: 0;
    box-shadow: hsl(210deg 87% 36%) 0px 7px 0px 0px;
    background-color: hsl(210deg 100% 44%);
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 12px;
    transition: 31ms cubic-bezier(.5, .7, .4, 1);
  }
  
  .button-Contactus:before {
    content: attr(alt);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset: 0;
    font-size: 15px;
    font-weight: bold;
    color: white;
    letter-spacing: 4px;
    opacity: 1;
  }
  
  .button-Contactus:active {
    box-shadow: none;
    transform: translateY(7px);
    transition: 35ms cubic-bezier(.5, .7, .4, 1);
  }
  
  .button-Contactus:hover:before {
    transition: all .0s;
    transform: translateY(100%);
    opacity: 0;
  }
  
  .button-Contactus i {
    color: white;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 4px;
    font-style: normal;
    transition: all 2s ease;
    transform: translateY(-20px);
    opacity: 0;
  }
  
  .button-Contactus:hover i {
    transition: all .2s ease;
    transform: translateY(0px);
    opacity: 1;
  }
  
  .button-Contactus:hover i:nth-child(1) {
    transition-delay: 0.045s;
  }
  
  .button-Contactus:hover i:nth-child(2) {
    transition-delay: calc(0.045s * 3);
  }
  
  .button-Contactus:hover i:nth-child(3) {
    transition-delay: calc(0.045s * 4);
  }
  
  .button-Contactus:hover i:nth-child(4) {
    transition-delay: calc(0.045s * 5);
  }
  
  .button-Contactus:hover i:nth-child(6) {
    transition-delay: calc(0.045s * 6);
  }
  
  .button-Contactus:hover i:nth-child(7) {
    transition-delay: calc(0.045s * 7);
  }
  
  .button-Contactus:hover i:nth-child(8) {
    transition-delay: calc(0.045s * 8);
  }
  
  .button-Contactus:hover i:nth-child(9) {
    transition-delay: calc(0.045s * 9);
  }
  
  .button-Contactus:hover i:nth-child(10) {
    transition-delay: calc(0.045s * 10);
  }
  
  