.user-feedback-container {
    display: flex;
    padding: 20px;
    background-color:#EFEEE6;
    /* background-color: #e4e4e4; */
}
.rev-container{
    display: flex;
}
.user-review-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    text-align: justify;
    margin: 1rem;
    text-overflow: ellipsis;
    /* Adjust height as needed */
    line-height: 1.5;
    background-color: #f5f5f5;
    padding: 0.6em;
    font-family: 'Roboto', sans-serif; /* Use Roboto font */
    border-color: rgb(225, 225, 225);
    border-style: solid;
    border-radius: 12px;
}
.user-review{
    overflow: hidden;
}


/*checking*/
.user-feedback-container {
    overflow: hidden; /* Hide overflowing content */
    white-space: nowrap; /* Prevent reviews from wrapping */
}

.user-review-card {
    display: inline-block; /* Display reviews in a row */
    margin-left: 60px; /* Adjust spacing between reviews */
    animation: scrollReviews 15s linear infinite; /* Adjust duration as needed */
}

@keyframes scrollReviews {
    0% {
        transform: translateX(0); /* Start from initial position */
    }
    100% {
        transform: translateX(calc(-100% - 20px)); /* Move to the left by review width + spacing */
    }
}


/*For Mobile Devices*/
@media only screen and (max-width: 767px){
 
}

/*For Tablets*/
@media only screen and (min-width: 768px) and (max-width: 991px){
 
}

/*For Laptops*/
@media only screen and (min-width:992px) and (max-width:1199px){

}

/* Large Devices like desktop and TV and more large*/
@media only screen and (min-width:1200px) {
    
}