.userregistration-container{
    margin-top: 120px;
}
.option-regstration{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    margin-bottom: -10px;
}
.userregistration-container {
    width: 80%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .userregistration-container h2 {
    text-align: center;
    margin-bottom: 10px;
    color: #333333;
  }
  
  .userregistration-container form div {
    margin-bottom: 20px;
  }
  
  .userregistration-container form label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    color: #555555;
  }
  
  .userregistration-container form input {
    width: 100%;
    padding: 12px;
    border: 1px solid #cccccc;
    border-radius: 6px;
    box-sizing: border-box;
    font-size: 16px;
  }
  
  .userregistration-container form button {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 6px;
    background-color: #4caf50;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .userregistration-container form button:hover {
    background-color: #45a049;
  }
  
  /* Media queries for responsiveness */
  
  @media (max-width: 768px) {
    .userregistration-container {
      width: 90%;
    }
  }
  
  @media (max-width: 480px) {
    .userregistration-container form input {
      padding: 10px;
      font-size: 14px;
    }
  
    .userregistration-container form button {
      padding: 10px;
      font-size: 16px;
    }
  }
  