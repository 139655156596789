/* General styles */
body {
    background-color: #f4f4f4;
    font-family: 'Arial', sans-serif;
}

/* Container styles */
.container {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 10px; /* Rounded corners for the container */
}

.image {
    display: block;
    width: 100%;
    height: 100%; /* Increased image height for larger display */
    object-fit: cover;
    transition: transform 0.5s ease; /* Smooth transition for scaling */
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 140, 186, 0.8); /* Semi-transparent overlay */
    overflow: hidden;
    width: 0;
    height: 100%;
    transition: width 0.5s ease; /* Smooth transition for width */
    border-radius: 10px; /* Rounded corners for the overlay */
}

.container:hover .image {
    transform: scale(1.1); /* Slightly zoom in the image on hover */
}

.container:hover .overlay {
    width: 100%;
}

.text {
    white-space: nowrap;
    color: white;
    font-size: 20px;
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

/* Card styles */
.card {
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    height: 100%;
    margin-bottom: 25px;
    border-radius: 10px; /* Rounded corners for the card */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for transform and shadow */
}

.card:hover {
    transform: translateY(-10px); /* Slightly lift the card on hover */
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.3); /* Enhance the shadow on hover */
}

/* Button styles */
.btn_buybutton {
    width: 100%;
    margin-bottom: 12px;
    background-color: #28a745;
    color: white;
    transition: background-color 0.3s ease, transform 0.3s ease;
    border-radius: 5px; /* Rounded corners for the button */
}

.btn_buybutton:hover {
    background-color: #218838; /* Darken button color on hover */
    transform: scale(1.05); /* Slightly enlarge the button on hover */
}

/* Price and available sizes text */
.pricetext {
    color: rgb(188, 122, 0);
    font-weight: bold;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
}

.Available {
    font-weight: bold;
}

/* Heading styles */
.Honey-heading, .Coffee-Heading {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.Honey-products-heading, .Coffee-products-heading {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
}

/* Page-specific container styles */
.HoneyMain-Container, .CoffeeMain-Container {
    padding: 20px;
}

.products-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.product-card {
    width: 25rem; /* Fixed width for the product card */
    margin: 10px;
}
