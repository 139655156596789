.Coffee-product-container-Robu {
    /* background-color: #729b5f82; */
    background-image: url("https://res.cloudinary.com/drevfgyks/image/upload/v1711635192/brown-coffee-droplets-fly-around-cup-chaotic-artistic-splash_burhue.jpg");
    background-size: cover;
    width: 100%;
}
.colorselft{
    color: white;
}
.preview-product-img{
    height: 8em;
    width: 8em;
    padding: 15px;
    border-radius: 10px;
    padding-top: 20px;
}

.preview-product-img:hover{
    border-style: solid;
    color: rgb(71, 71, 218);
    border-width: 0.5px;
   
}
.product-pics-container{
    background-color: white;
    height: 453px;
    background-size: cover;
    border-radius: 8px;
}
.main-product-img{
    height: 20em;
}
.preview-imgs{
    display: flex;
    justify-content: center;
    align-items: center;
}

.honeybuypage-container{
    background-image: linear-gradient( #154851, #874528);
    background-size: cover;
    height: 100vh;
    display: flex;
    /* flex-direction: row-reverse; */
    margin-top: 5%;
    gap: 5em;
    padding: 4em;
}

.name-for-product{
    font-size: 2em;
}

.prodcut-size-card{
    display: flex;
    gap: 1.5em;
}
.prodcut-size-Robusta{
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-width: 0.2px;
    padding: 0.5em;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 500;
    font-family: 'Roboto';
}

.prodcut-size-Robusta:hover{
    scale: 1.1;
    background-color: #346700;
    color: white;
}

.product-quantity{
    display: flex;
    align-items: center;
    gap: 1em;
}

.add-to-cart-btn{
    margin: 1em;
    background-color: rgb(238, 165, 29);
    width: 12em;
    border-style: none;
    padding: 1em;
    color: #fff;
    border-radius: 8px;
    box-shadow: 0 13px 27px 0 rgb(93, 84, 53);
    text-transform: uppercase;
    transition: .5s;
    cursor: pointer;
}

.buy-now-btn{
    margin: 1em;
    background-color: rgb(0, 160, 196);
    border-style: none;
    padding: 1em;
    color: #fff;
    width: 12em;
    border-radius: 8px;
    box-shadow: 0 13px 27px 0 rgb(93, 84, 53);
    text-transform: uppercase;
    transition: .5s;
    cursor: pointer;
}

.add-to-cart-btn:hover,.buy-now-btn:hover{
    border-color: blue;
    scale: 1.1;
}

.prdouct-card{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgb(246, 245, 245);
    background-size: cover;
    width: 50%;
    padding: 22px;
    height: 450px;
    border-radius: 8px;
}
.product-discription{
    font-size: 18px;
    text-align: start;
}
.product-price{
    font-size: 28px;
    align-self: flex-start;
}
.price-span{
    color: black;
    font-size: bold;
    font-size: 18px;
}


/*Media Query CSS Design */

@media screen and (max-width: 475px){
    .honeybuypage-container{
        background-image: linear-gradient( #ea00ff, #897305);
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .product-pics-container{
        margin-top: 300px;
    }
    .prdouct-card{
        background-size: cover;
        height: 100vh;
    }
}
@media screen and  (min-width: 640px){
    .honeybuypage-container{
        background-image: linear-gradient( #000000, #1b2a68);
        background-size: cover;
        height: 1250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .product-pics-container{
        margin-top: 60px;
    }
    .prdouct-card{
        background-size: cover;
        width: 100%;
        height: 100vh;
    }
    .action-buttons{
        display: flex;
    }
} 
@media screen and  (min-width: 768px){
    .honeybuypage-container{
        background-image: linear-gradient( #000000, #1b2a68);
        background-size: cover;
        height: 1250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .product-pics-container{
        margin-top: 60px;
    }
    .prdouct-card{
        background-size: cover;
        width: 100%;
        height: 100vh;
    }
    .action-buttons{
        display: flex;
    }
}
.select-container{
    height: 40px;
}


/*For Mobile Devices*/
@media only screen and (max-width: 767px){
    .prodcut-size-Robusta{
        font-size: 12px;
        box-shadow: 0 13px 27px 0 rgb(149, 149, 149);
        border-style: none;
        border-color: none;
        border-radius: 8px;
    }
    .cardcenter{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }  

}

/*For Tablets*/
@media only screen and (min-width: 768px) and (max-width: 991px){
    .prodcut-size-Robusta{
        text-align: center;
        font-size: 16px;
        width: 120px;
        box-shadow: 0 13px 27px 0 rgb(149, 149, 149);
        border-style: none;
        border-color: none;
        border-radius: 8px;
    }
    .cardcenter{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
    }
    .Honey-product-container{
        width: 100%;
    }
}

/*For Laptops*/
@media only screen and (min-width:992px) and (max-width:1199px){
    .prodcut-size-Robusta{
        text-align: center;
        font-size: 16px;
        width: 120px;
        box-shadow: 0 13px 27px 0 rgb(149, 149, 149);
        border-style: none;
        border-color: none;
        border-radius: 8px;
    }
    .cardcenter{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 35px;
    }
    
}

/* Large Devices like desktop and TV and more large*/
@media only screen and (min-width:1200px) {
    .prodcut-size-Robusta{
        text-align: center;
        font-size: 16px;
        width: 190px;
        box-shadow: 0 13px 27px 0 rgb(149, 149, 149);
        border-style: none;
        border-color: none;
        border-radius: 8px;
    }
    .cardcenter{
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 65px;
        margin-bottom: 105px;
    }
}
