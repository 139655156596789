.develiry-from-container {
    max-width: 400px;
    margin: 2em auto;
    padding: 20px;
  }
  
  
  
  .title-text {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .from-card {
    display: flex; /* Use flexbox for label and input alignment */
    align-items: center; /* Align items vertically */
    margin-bottom: 15px;
  }
  
  .from-card label {
    font-weight: bold;
    flex: 1; /* Allow label to grow and take up remaining space */
  }
  
  .from-card input,
  .from-card textarea {
    flex: 2; /* Allow input to take up more space */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .adr-box {
    height: 100px; /* Adjust height as needed */
    resize: none; /* Prevent textarea from being resizable */
  
  }
  
  .from-card button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .from-card button:hover {
    background-color: #0056b3;
  }