.Mottos{
    border-radius: 50px;
    margin-bottom: 80px;
}


/*For Mobile Devices*/
@media only screen and (max-width: 767px){
    .about-us-section {
        flex-direction: column;
        width: 100%;
    }
    .aboutush2{
        font-size: 38px;
        margin-top: 70px;
        text-align: center;
    }
    .aboutusparagraph{
        font-size: 12px;
        margin: 12px;
    }
    .about-us-icon{
        display: flex;
        align-items: center;
    }
    .icons{
        font-size: 45px;
        margin-left: 15px;
        padding: 15px;
    }
    .aboutus-para{
        font-size: 14px;
    }
    .about-us-image {
        width: 100%; /* Make both content and image full width on smaller screens */
    }

    .about-us-image {
        margin-top: 20px; /* Add some space between content and image */
    }
}

/*For Tablets*/
@media only screen and (min-width: 768px) and (max-width: 991px){
    .about-us-image {
        width: 100%; /* Make both content and image full width on smaller screens */
    }
    .about-us-section {
        flex-direction: column;
        width: 100%;
    }
    .aboutush2{
        font-size: 38px;
        margin-top: 100px;
        text-align: center;
    }
    .aboutusparagraph{
        font-size: 14px;
        margin: 12px;
    }
    .about-us-icon{
        display: flex;
        align-items: center;
    }
    .icons{
        font-size: 65px;
        margin-left: 25px;
        padding: 15px;
    }
    .aboutus-para{
        font-size: 20px;
    }
}

/*For Laptops*/
@media only screen and (min-width:992px) and (max-width:1199px){
    .about-us-image {
        margin-top: 65px;
        width: 300px; /* Make both content and image full width on smaller screens */
    }
    .about-us-content{
        width: 550px;
    }
    .about-us-section {
        display: flex;
        justify-content: space-around;
    }
    .aboutush2{
        font-size: 25px;
        margin-top: 110px;
        margin-left: 10px;
    }
    .aboutusparagraph{
        font-size: 12px;
        margin: 10px;
    }
    .about-us-icon{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .icons{
        margin-top: 25px;
        font-size: 45px;
        margin-left: 25px;
        padding: 15px;
    }
    .aboutus-para{
        font-size: 12px;
    }
    .processofproducts{
        display: flex;
        justify-content: space-evenly;
    }
}

/* Large Devices like destop and TV and more large*/
@media only screen and (min-width:1200px) {
    .about-us-image {
        margin-top: 70px;
        width: 450px; /* Make both content and image full width on smaller screens */
    }
    .about-us-content{
        width: 550px;
    }
    .about-us-section {
        display: flex;
        justify-content: space-around;
    }
    .aboutush2{
        font-size: 45px;
        margin-top: 110px;
    }
    .aboutusparagraph{
        font-size: 15px;
        margin: 10px;
    }
    .about-us-icon{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .icons{
        margin-top: 25px;
        font-size: 45px;
        padding: 15px;
    }
    .aboutus-para{
        font-size: 12px;
    }
    .processofproducts{
        display: flex;
        justify-content: space-evenly;
    }
    .aboutush2self{
        margin-top: 150px;
    }
}

