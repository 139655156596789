/* CheckoutPage.css */
  
  .CheckoutPage-checkout-form-container {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .CheckoutPage-checkout-heading {
    margin-bottom: 50px;
    text-align: center;
  }
  
  .CheckoutPage-form-group {
    margin-bottom: 20px;
  }
  
  .CheckoutPage-form-control {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .CheckoutPage-btn {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .CheckoutPage-btn-primary {
    background-color: #007bff;
    color: #fff;
  }
  
  .CheckoutPage-btn-primary:hover {
    background-color: #0056b3;
  }
  
  .CheckoutPage-btn-secondary {
    background-color: #6c757d;
    color: #fff;
  }
  
  .CheckoutPage-btn-secondary:hover {
    background-color: #545b62;
  }

  /* CSS styles for CheckoutPage */

.checkoutpage-form {
    width: 100%;
  }
  
  .checkoutpage-form-group {
    margin-bottom: 20px;
  }
  
  .checkoutpage-label {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .checkoutpage-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .checkoutpage-input:focus {
    border-color: #007bff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .checkoutpage-btn {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .checkoutpage-btn:hover {
    background-color: #0056b3;
  }
  .selected-product-details{
    align-self: flex-start;
    margin-top: 50px;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    border-width: 2px;
  }
  .Selected-heading{
    text-align: center;
    font-size: 18px;
  }
  /* .Checkout123{
    border-image: fill 0 linear-gradient(#0001, #000);
  } */
  
  /*For Mobile Devices*/
@media only screen and (max-width: 767px){
  .CheckoutPage-checkout-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    padding: 10px;
  }
  .selected-product-details{
    margin-top: 60px;
  }
  .CheckoutPage-checkout-form-container {
    margin-top: 50px;
    margin-bottom: 150px;
  }
  .Girl-image{
    display: none;
  }
  .Checkout{
    display: none;
  }
}

/*For Tablets*/
@media only screen and (min-width: 768px) and (max-width: 991px){
  .CheckoutPage-checkout-container{
    display: flex;
    justify-content: space-evenly;
    margin-top: 12px;
    padding: 10px;
    background-color: rgb(255, 255, 255);
  }
  .selected-product-details{
    margin-top: 10px;
    margin-bottom: 45px;
  }
  .CheckoutPage-checkout-form-container {
    margin-top: 50px;
    margin-bottom: 150px;
  }
  .Girl-image{
    margin-top: 120px;
    width: 50%;
    height: 100%;
  }
  .Checkout{
    display: none;
  }
}

/*For Laptops*/
@media only screen and (min-width:992px) and (max-width:1199px){
  .CheckoutPage-checkout-container{
    display: flex;
    justify-content: space-evenly;
    margin-top: 12px;
    padding: 10px;
  }
  .selected-product-details{
    margin-top: 60px;
  }
  .CheckoutPage-checkout-form-container {
    margin-top: 50px;
    margin-bottom: 150px;
  }
  .Girl-image{
    display: none;
  }
  .Checkout{
    margin-top: 120px;
    width: 50%;
    height: 100%;
  }
}

/* Large Devices like desktop and TV and more large*/
@media only screen and (min-width:1200px) {
  .CheckoutPage-checkout-container{
    display: flex;
    justify-content: space-evenly;
    margin-top: 12px;
    padding: 10px;
  }
  .selected-product-details{
    margin-top: 5px;
    margin-bottom: 60px;
  }
  .CheckoutPage-checkout-form-container {
    margin-top: 80px;
    margin-bottom: 150px;
  }
  .Girl-image{
    display: none;
  }
  .Checkout{
    margin-top: 120px;
    width: 40%;
    height: 100%;
  }
}





/* Existing CSS for CheckoutPage */
/* ... existing styles ... */

/* Loader CSS */
.loader {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.truckWrapper {
  width: 200px;
  height: 100px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  overflow-x: hidden;
}

/* truck upper body */
.truckBody {
  width: 130px;
  height: fit-content;
  margin-bottom: 6px;
  animation: motion 1s linear infinite;
}

/* truck suspension animation*/
@keyframes motion {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* truck's tires */
.truckTires {
  width: 130px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0px 15px;
  position: absolute;
  bottom: 0;
}

.truckTires svg {
  width: 24px;
}

.road {
  width: 100%;
  height: 1.5px;
  background-color: #282828;
  position: relative;
  bottom: 0;
  align-self: flex-end;
  border-radius: 3px;
}

.road::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 100%;
  background-color: #282828;
  right: -50%;
  border-radius: 3px;
  animation: roadAnimation 1.4s linear infinite;
  border-left: 10px solid white;
}

.road::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 100%;
  background-color: #282828;
  right: -65%;
  border-radius: 3px;
  animation: roadAnimation 1.4s linear infinite;
  border-left: 4px solid white;
}

.lampPost {
  position: absolute;
  bottom: 0;
  right: -90%;
  height: 90px;
  animation: roadAnimation 1.4s linear infinite;
}

@keyframes roadAnimation {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-350px);
  }
}
  