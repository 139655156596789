.coffee-banner{
    height: 20vh;
}
.coffee-products-heading2{
    color: white;
    font-family: informal;
    font-size: 30px;
}
/* .Container-card{
    background-color: rgb(153, 153, 153);
    width: 100%;
    border-radius: 12px;
} */
.card{
    background-color: rgb(255, 255, 255);
    /* background-color: transparent; */
   
}
.Coffee-Heading{
    font-size: 15px;
    font-family: 'Georgia', serif;
}
.card-text{
    font-style: italic;
}

.emptyBOX-para2{
    font-size: 15px;
    font-family: 'Roboto';
    text-align: start;
    margin-top: 12px;
}

/*For Mobile Devices*/
@media only screen and (max-width: 767px){
    .products-container{
        display: flex;
        justify-content: space-evenly;
    }
}

/*For Tablets*/
@media only screen and (min-width: 768px) and (max-width: 991px){
    .products-container{
        display: flex;
        justify-content: space-evenly;
    }
}

/*For Laptops*/
@media only screen and (min-width:992px) and (max-width:1199px){
    .products-container{
        display: flex;
        justify-content: space-evenly;
    }
}

/* Large Devices like desktop and TV and more large*/
@media only screen and (min-width:1200px) {
    .coffee-products-container2{
        display: flex;
        justify-content: center;
        margin-top: -55px;
        gap: 230px;
        margin-right: 370px;
    }
    .coffee-main-title-container2{
        margin-left: 60px;
        margin-top: 60px;
        padding: 70px;
    }
    .CoffeeProduct2-container{
        /* background-image: url("https://res.cloudinary.com/drevfgyks/image/upload/v1715955816/coffee-ai-generated_bkwpbw.jpg"); */
        background-image: url("https://res.cloudinary.com/drevfgyks/image/upload/v1716127044/view-3d-coffee-cup_uwmgwi.jpg");
        height: 100vh;
        background-size: cover;
        width: 100%;
    }
    .coffee-emptyBOX-container{
        display: flex;
        justify-content: center;
        gap: 70px;
        margin-right: 370px;
    }
    .coffee-emptyBOX{
        margin-left: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        width: 650px;
        height: 150px;
        border-bottom-right-radius: 100px;
        border-top-right-radius: 100px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
        box-shadow: 10px 10px rgb(104, 97, 72);
    }
}