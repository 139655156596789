/* adminDashboard.css */
.main-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.AdminDashboard_container1 {
    /* background-color: #efefef; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;
    max-height: 800px;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }
  .admin-logo{
    color: #686868;
    margin-bottom: 12px;
  }
  .admininputs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
  }
  .Admin-Heading{
    margin-top: 1050px;
  }
  .Inputs{
    width: 250px;
    height: 40px;
    margin: 8px;
    border-radius: 8px;
    /* background-color: #efefef; */
    border: solid 1px rgb(160, 160, 160);
  }
  .AdminDashboard_h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .AdminDashboard_h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .AdminDashboard_ul {
    list-style-type: none;
    padding: 0;
  }
  
  .AdminDashboard_li {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .AdminDashboard_button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
  }
  
  .AdminDashboard_button:hover {
    background-color: #0056b3;
  }
  
  .AdminDashboard_input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .AdminDashboard_error {
    color: red;
    margin-top: 5px;
  }
  
  /* adminDashboard.css */
  
  .AdminDashboard_form {
    max-width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .AdminDashboard_input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .AdminDashboard_button {
    width: 50%;
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .AdminDashboard_button:hover {
    background-color: #0056b3;
  }
  
  .AdminDashboard_error {
    color: red;
    margin-top: 5px;
  }
  
  .spinner {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: auto;
    display: block;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .main-container {
    position: relative;
  }
  
  .logout-icon-container {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .logout-icon {
    cursor: pointer;
    color: #333;
  }
  
  .logout-icon:hover {
    color: #000;
  }
  
  .admin-logo {
    display: block;
    margin: 0 auto 20px;
  }
  
  .admininputs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .Inputs {
    margin: 10px 0;
    padding: 10px;
    width: 80%;
    max-width: 300px;
  }
  
  .AdminDashboard_button {
    padding: 10px 20px;
    margin-top: 20px;
  }
  
  .AdminDashboard_error {
    color: red;
    margin-top: 10px;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  