/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Caveat:wght@400;700&family=Lobster&family=Monoton&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display+SC:ital,wght@0,400;0,700;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,700&family=Work+Sans:ital,wght@0,400;0,700;1,700&display=swap");
.container-nav{
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  /* background-color: #565c6d; */
  background-color: #274546;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 100vw;
}
.userlogin-navbar{
  padding-left: 58px;
  align-self: center;
}
.heading-container{
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: rgb(218, 218, 218);
  margin-right: 15px; /*changed*/
}
.borgnanics-heading{
  font-size:20px;
}

/* Base styles */
.Borgani-logo {
  width: 85px;
  height: 85px;
  font-family: "Roboto", sans-serif; /* Set default font */
}



.banner-heading {
  color: white;
  font-family: "Roboto", sans-serif; /* Set default font */
  font-size: 45px;
  font-weight: 300;
}

.banner-caption {
  color: #f5f7fa;
  font-family: "Roboto", sans-serif; /* Set default font */
  font-size: 24px;
  font-weight: 300;
}

.custom-button {
  color: white;
  background-image: linear-gradient(#d0b200, #a58d00);
  width: 130px;
  height: 45px;
  border-width: 0;
  border-radius: 8px;
  margin-right: 10px;
}

.custom-outline-button {
  color: #d0b200;
  background-color: transparent;
  width: 130px;
  height: 45px;
  border-style: solid;
  border-width: 1px;
  border-color: #d0b200;
  border-radius: 8px;
}
.Shop-Categories{
  color: white;
  border-style: none;
}
.Shop-Categories:hover{
  color: white;
}

.userlogin-navbar{
  display: flex;
  align-items: center;
  text-align: center;
  color: rgb(225, 225, 225);
  font-size: 20px;
}
.cart-icon{
  font-size: 30px;
  margin-left: 25px;
}
.nav-link :hover{
  font-size: 14px;
  color: rgb(255, 255, 255);
}
.cart-icon{
  color: white;
}


/* Media Queries */

/* Extra Small (Size < 576px) */
@media only screen and (max-width: 767px) {
  .container-fluid{
    width: 100%;
  }
  .container-nav{
    color: white;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    background-color: #274546;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  .heading-container{
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
  .container-fluid{
    width: 100%;
  }
  .container-nav{
    color: white;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    background-color: #274546;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  .heading-container{
    display: none;
  }
 
}

@media only screen and (min-width:992px) and (max-width:1199px){
  .container-fluid{
    width: 100%;
  }
  .container-nav{
    color: white;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    background-color: #274546;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (min-width:1200px){
  .app-container{
    width: 100%;
  }
  .container-nav{
    color: white;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    background-color: #274546;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  .cart-icon{
    color: white;
  }
  .nav-link{
    color: white;
  }
}

/* Base styles */
.nav-link {
  color: white;
  position: relative;
  text-transform: uppercase; /* Make the text uppercase for a modern look */
  letter-spacing: 1px; /* Add some spacing between letters */
  transition: color 0.3s ease-in-out;
  overflow: hidden; /* Ensure the underline animation stays within bounds */
  padding: 10px; /* Add some padding for better clickability */
}

.nav-link::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #d0b200;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.nav-link:hover {
  color: #d0b200; /* Change to desired hover color */
}

.nav-link:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

/* Hover effects for dropdown toggle */
.Shop-Categories.nav-link {
  color: white;
  position: relative;
  transition: color 0.3s ease-in-out;
  overflow: hidden;
  text-transform: uppercase; /* Make the text uppercase for a modern look */
  letter-spacing: 1px; /* Add some spacing between letters */
  padding: 10px; /* Add some padding for better clickability */
}

.Shop-Categories.nav-link::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #d0b200;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.Shop-Categories.nav-link:hover {
  color: #d0b200; /* Change to desired hover color */
}

.Shop-Categories.nav-link:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

/* Hover effects for dropdown items */
.dropdown-menu .dropdown-item {
  color: black; /* Default color for dropdown items */
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, padding-left 0.3s ease-in-out;
  text-transform: uppercase; /* Make the text uppercase for a modern look */
  letter-spacing: 1px; /* Add some spacing between letters */
}

.dropdown-menu .dropdown-item:hover {
  color: #d0b200; /* Change to desired hover color */
  background-color: #f0f0f0; /* Change to desired hover background color */
  padding-left: 10px; /* Add a sliding effect */
}

/* Additional CSS code */
/* ... */
