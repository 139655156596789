.socialMedia{
    color: white;
    text-decoration: none;
}
.media-faWhatsapp:hover {
  background-color: #3dbb2a; /* A shade of green */
}

.media-faLinkedin:hover {
  background-color: #0e76a8; /* A shade of blue */
}

.media-faInstagram:hover {
  background-color: #e95950; /* A shade of red */
}

.media-faEnvelope:hover {
  background-color: #f7d417; /* A shade of yellow */
}
.company-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.whatsapp-icon{
  color: white;
  cursor: pointer;
}
.company-container2{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.socialMedia{
    display: flex;
    align-items: center;
}
.company-container{
  display: flex;
  align-items: center;
}
.logofooter {
    width: 80px;
}
.footer-comapnay-name{
  color: rgb(223, 223, 223);
  font-size: 15px;
}

/*lll*/

.bg-container {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px; 
  /* background-color: #0e3500b6; */
  background-color: #000000;
  background-size: cover;
} 
.media {
  display: flex;
  background-color: #000204;  
  text-align: center;
  font-size: 18px;
  margin: 10px;
  padding: 10px;
  border-radius: 20px;
  color: #a2a2a2;
  text-decoration: none;
}

.headingfooter {
  color: #a2a2a2;
  font-size: 14px;
}

.heading2 {
  color: #dad9d9;
  font-size: 14px;
  font-weight: bold;
}

.para {
  text-decoration: none;
  color: #a2a2a2;
  font-size: 14px;
}

.hr-line {
  background-color: #eeeeee;
  width: 90%;

}
.footer-section-mail-id{
  text-decoration: none;
}



/*For Mobile Devices*/
@media only screen and (max-width: 767px){
    .SocialMedia-Follow-container{
      display: flex;
      flex-direction: column;
    }
    .Social-icons-container{
      display: flex;
      margin-top: -20px;
    }
    .promise_Infor-conatiner{
      display: flex;
      justify-content: space-evenly;
      margin-top: 25px;
    }
}

/*For Tablets*/
@media only screen and (min-width: 768px) and (max-width: 991px){
  .SocialMedia-Follow-container{
    display: flex;
    flex-direction: column;
  }
  .Social-icons-container{
    display: flex;
    margin-top: -20px;
  }
  .promise_Infor-conatiner{
    display: flex;
    justify-content: space-evenly;
    margin-top: 25px;
  }
  .para, .headingfooter{
    font-size: 15px;
    font-family: 'Roboto';
  }
  .footer-comapnay-name, .heading2{
    font-size: 18px;
    font-family: cursive;
  }
}

/*For Laptops*/
@media only screen and (min-width:992px) and (max-width:1199px){
  .SocialMedia-Follow-container{
    display: flex;
    flex-direction: column;
  }
  .Social-icons-container{
    display: flex;
    margin-top: -20px;
  }
  .promise_Infor-conatiner{
    display: flex;
    justify-content: space-evenly;
    margin-top: 25px;
  }
  .para, .headingfooter{
    font-size: 15px;
    font-family: 'Roboto';
  }
  .footer-comapnay-name, .heading2{
    font-size: 18px;
    font-family: cursive;
  }
}

/* Large Devices like desktop and TV and more large*/
@media only screen and (min-width:1200px) {
  .Footer-container{
    display: flex;
    justify-content: space-evenly;
  }
  .Social-icons-container{
    display: flex;
  }
  .promise_Infor-conatiner{
    display: flex;
    gap: 120px;
    margin-top: 35px;
  }
  .logofooter{
    margin-top: 35px;
  }
  .para, .headingfooter{
    font-size: 15px;
    font-family: 'Roboto';
  }
  .footer-comapnay-name, .heading2{
    font-size: 18px;
    font-family: sans-serif;
  }
}

